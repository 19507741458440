<template>
  <div
    v-if="row?.teams?.length"
    class="manual-edit-table-cell-root"
  >
    <div
      v-for="team in row?.teams"
      :key="team?.teamId"
      class="manual-edit-table-cell-content"
    >
      <span
        class="manual-edit-table-cell"
        v-html="formatValue(team?.teamId) ?? '-'"
      />
      <div
        class="copy-icon-root"
        @click.stop="copyToClipboard(team?.teamId)"
      >
        <Icon
          name="copy"
        />
      </div>
    </div>
  </div>
  <div
    v-else
    class="manual-edit-table-cell-root"
  >
    <span class="manual-edit-table-cell">-</span>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';
import { copyToClipboard } from '@/services/helpers/mappings-mapper';

export default {
  components: {
    Icon,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const formatValue = (value) => (props.row.teams.length > 1 ? `${value.slice(0, 5) }...` : value);

    return {
      formatValue,
      copyToClipboard,
    };
  },
};
</script>
<style lang="scss">
.manual-edit-table-cell-root {
  display: flex;
  gap: 8px;
  .manual-edit-table-cell-content {
    position: relative;
    overflow: hidden;
    .manual-edit-table-cell {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 22px;
    }
    .copy-icon-root {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      background-color: $white;
      svg path {
        stroke: $black;
      }
    }
  }
}
</style>
