<template>
  <div class="manual-edit-table-cell-root">
    <span
      class="manual-edit-table-cell"
      v-html="value"
    />
    <div
      v-if="copyIconActive"
      class="copy-icon-root"
      @click.stop="copyValue"
    >
      <Icon
        name="copy"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { map } from 'lodash';
import { formatPlayerName } from '@/services/helpers/player-setup-mapper';
import Icon from '@/components/common/Icon';
import { copyToClipboard } from '@/services/helpers/mappings-mapper';

export default {
  components: {
    Icon,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    copyAvailable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const value = computed(() => {
      if (props.columnKey === 'name') return formatPlayerName(props.row?.[props.columnKey]) || '-';
      if (props.columnKey === 'teamName') return map(props.row?.teams, (team) => team.teamName).join(', ') || '-';
      return props.row?.[props.columnKey] || '-';
    });

    const copyIconActive = computed(() => props.copyAvailable && props.row?.[props.columnKey]);

    const copyValue = () => {
      copyToClipboard(props.row?.[props.columnKey]);
    };

    return {
      value,
      copyIconActive,
      copyValue,
    };
  },
};
</script>
<style lang="scss">
.manual-edit-table-cell-root {
  position: relative;
  overflow: hidden;
  .manual-edit-table-cell {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 22px;
  }
  .copy-icon-root {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    background-color: $white;
    svg path {
      stroke: $black;
    }
  }
}
</style>
