<template>
  <div
    class="add-team-to-player-search"
  >
    <div class="team-search-header">
      <Dropdown
        :label="selectedSearchTeamByLabel"
      >
        <DropdownItem
          clickable
          @click="onSearchTeamByChange('team-name')"
        >
          Team name
        </DropdownItem>
        <DropdownItem
          clickable
          @click="onSearchTeamByChange('team-id')"
        >
          Team ID
        </DropdownItem>
      </Dropdown>
      <TextInput
        ref="searchRoot"
        class="search-field"
        :model-value="teamSearch"
        icon="search"
        placeholder="Search team"
        small
        @update:modelValue="updateSearch"
        @focus="onTeamSearchInputFocus"
      />
    </div>
    <div :class="['team-search-body', { 'visible': teamSearchListVisible }]">
      <Spinner
        v-if="teamsLoading"
        small
      />
      <p v-if="!teamsLoading && !teams.length">
        No teams found
      </p>
      <div
        v-if="!teamsLoading && teams.length"
        class="team-items"
      >
        <div
          v-for="team in teams"
          :key="team.teamId"
          class="team-item"
          @click="addTeamToPlayer(team)"
        >
          <div>{{ team.teamName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, onMounted, ref,
} from 'vue';
import { useStore } from 'vuex';
import {
  debounce, map,
} from 'lodash';
import { onClickOutside } from '@vueuse/core';
import TextInput from '@/components/common/TextInput';
import Spinner from '@/components/common/Spinner';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';

export default {
  components: {
    TextInput,
    Spinner,
    Dropdown,
    DropdownItem,
  },
  emits: ['onTeamAdd'],
  props: {
  },
  setup(props, { emit }) {
    const searchRoot = ref(null);
    const store = useStore();
    const teamSearch = ref(props.teamName || '');
    const teams = computed(() => map(store.getters.manualEditTeamSearchList, (data) => ({
      teamId: data.teamId,
      teamName: data.name,
    })));
    const teamsLoading = computed(() => store.getters.manualEditTeamSearchListLoading);
    const teamSearchListVisible = ref(false);
    const selectedSearchTeamBy = ref('team-name');
    const selectedSearchTeamByLabel = computed(() => {
      if (selectedSearchTeamBy.value === 'team-id') {
        return 'Team ID';
      }
      return 'Team name';
    });

    const loadManualEditSearchTeams = () => {
      store.dispatch('loadManualEditTeamsSearch', {
        searchValue: teamSearch.value,
        searchBy: selectedSearchTeamBy.value,
      });
    };

    const updateSearch = debounce((val) => {
      teamSearch.value = val;
      loadManualEditSearchTeams();
    }, 500);

    const onTeamSearchInputFocus = () => { teamSearchListVisible.value = true; };

    const addTeamToPlayer = (team) => {
      emit('onTeamAdd', team);
    };

    onClickOutside(searchRoot, () => {
      teamSearchListVisible.value = false;
    });

    const onSearchTeamByChange = (searchBy) => {
      selectedSearchTeamBy.value = searchBy;
      loadManualEditSearchTeams();
    };

    onMounted(() => loadManualEditSearchTeams());

    return {
      searchRoot,
      teamSearch,
      teams,
      teamsLoading,
      updateSearch,
      teamSearchListVisible,
      selectedSearchTeamByLabel,
      onTeamSearchInputFocus,
      addTeamToPlayer,
      onSearchTeamByChange,
    };
  },
};
</script>

<style lang="scss">
.add-team-to-player-search {
  background-color: $white;
  position: relative;
  border-radius: 4px;

  .team-search-header {
    position: sticky;
    display: flex;
    align-items: center;
    gap: 8px;
    top: 0;
    left: 0;
    background-color: $white;
    .search-field {
      width: 100%;
    }
  }
  .team-search-body {
    width: 100%;
    height: 250px;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(100% + 2px);
    z-index: 10;
    background-color: $white;
    border: 1px solid $gray500;
    box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.12);

    &.visible {
      display: flex;
    }

    .team-items {
      width: 100%;
      overflow: auto;
      height: 100%;

      .team-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding: 8px;
        min-height: 50px;
        cursor: pointer;
        .add-team-to-squad-btn {
          display: none;
        }

        &:hover {
          background-color: $gray300;
          .add-team-to-squad-btn {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
