<template>
  <div class="edit-player-modal-content">
    <div class="edit-player-form">
      <div
        v-if="confirmDeleteActive"
        class="disabled-layer-root"
      />
      <div class="player-info-title">
        <p>Player display information</p>
      </div>
      <div class="player-info">
        <div class="input-label">
          First name
        </div>
        <TextInput
          :model-value="firstName"
          :placeholder="'Input first name'"
          no-clear-button
          @update:modelValue="onPlayerFirstNameUpdate"
        />
        <div class="input-label">
          Last name
        </div>
        <TextInput
          :model-value="lastName"
          :placeholder="'Input last name'"
          no-clear-button
          @update:modelValue="onPlayerLastNameUpdate"
        />
        <div class="input-label">
          Team name
        </div>
        <AddTeamToPlayerSearch
          @onTeamAdd="addTeamToPlayer"
        />
        <div
          v-if="teams.length"
          class="team-squad"
        >
          <div
            v-for="team in teams"
            :key="team.teamId"
            class="player-item"
          >
            <div class="player-info">
              <span>{{ team?.teamName }}</span>
            </div>
            <div class="player-actions">
              <div class="remove-player-btn">
                <Icon
                  @click="removeTeam(team.teamId)"
                  name="trash"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="no-squad-data"
        >
          No squad data
        </div>
        <PlayerPositionEdit
          :position="data.position"
          @updatePosition="onPlayerPositionUpdate"
        />
      </div>
    </div>
    <div
      v-if="!confirmDeleteActive"
      class="player-squad-actions"
    >
      <div class="left-side">
        <div
          v-if="!isCreate"
          class="delete-btn"
          @click="toggleConfirmDelete"
        >
          Delete player
        </div>
      </div>
      <div class="right-side">
        <div
          @click="closeModal"
          class="dismiss-btn"
        >
          Dismiss
        </div>
        <Button
          :variant="'primary'"
          @click="onSaveChanges"
          :disabled="isSubmitDisabled"
        >
          Save changes
        </Button>
      </div>
    </div>
    <div
      v-else
      class="confirm-delete"
    >
      <div class="left-side">
        Confirm delete for player {{ formatPlayerName(playerData.name) }}
      </div>
      <div class="right-side">
        <div
          @click="toggleConfirmDelete"
          class="dismiss-btn"
        >
          Dismiss
        </div>
        <Button
          :variant="'danger'"
          @click="deletePlayer"
        >
          Delete player
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
  cloneDeep, isEqual, orderBy, filter, map, some,
} from 'lodash';
import {
  formatPlayerName,
  formatPlayerFirstName,
  formatPlayerLastName,
} from '@/services/helpers/player-setup-mapper';
import TextInput from '@/components/common/TextInput';
import Button from '@/components/common/Button';
import PlayerPositionEdit from './PlayerPositionEdit';
import AddTeamToPlayerSearch from './AddTeamToPlayerSearch';
import Icon from '@/components/common/Icon';

export default {
  emits: ['closeModal'],
  props: {
    playerData: {
      type: Object,
      default: () => {},
    },
    isCreate: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    TextInput,
    Button,
    PlayerPositionEdit,
    AddTeamToPlayerSearch,
    Icon,
  },
  setup(props, { emit }) {
    const store = useStore();
    const originalData = ref(cloneDeep(props.playerData));
    const data = ref(cloneDeep(props.playerData));
    const isSubmitDisabled = computed(() => isEqual(originalData.value, data.value));
    const firstName = ref(formatPlayerFirstName(props.playerData.name));
    const lastName = ref(formatPlayerLastName(props.playerData.name));
    const confirmDeleteActive = ref(false);
    const teams = computed(() => orderBy(data.value.teams, 'name'));

    const updatePlayerName = () => {
      data.value.name = `${lastName.value}, ${firstName.value}`;
    };

    const onPlayerFirstNameUpdate = (val) => {
      firstName.value = val;
      updatePlayerName();
    };
    const onPlayerLastNameUpdate = (val) => {
      lastName.value = val;
      updatePlayerName();
    };
    const onPlayerPositionUpdate = (val) => {
      data.value.position = val;
    };
    const toggleConfirmDelete = () => { confirmDeleteActive.value = !confirmDeleteActive.value; };
    const closeModal = () => emit('closeModal');
    const onSaveChanges = () => {
      const player = {
        name: data.value.name,
        position: data.value.position,
        playerId: data.value.playerId,
        teamIds: map(data.value.teams, (team) => team.teamId),
      };
      if (props.isCreate) {
        store.dispatch('manuallyCreatePlayer', player);
      } else {
        store.dispatch('updatePlayerData', player);
      }
    };
    const deletePlayer = () => store.dispatch('manuallyRemovePlayer', props.playerData);
    const removeTeam = (teamId) => {
      data.value.teams = [...filter(data.value.teams, (team) => team.teamId !== teamId)];
    };
    const addTeamToPlayer = (team) => {
      if (!Array.isArray(data.value.teams)) {
        data.value.teams = [];
      }
      if (!some(data.value.teams, (t) => t.teamId === team.teamId)) {
        data.value.teams = [...data.value.teams, team];
      }
    };

    return {
      firstName,
      lastName,
      teams,
      isSubmitDisabled,
      confirmDeleteActive,
      onPlayerFirstNameUpdate,
      onPlayerLastNameUpdate,
      onSaveChanges,
      closeModal,
      formatPlayerName,
      toggleConfirmDelete,
      deletePlayer,
      onPlayerPositionUpdate,
      data,
      addTeamToPlayer,
      removeTeam,
    };
  },
};
</script>

<style lang="scss">
  .edit-player-modal-content {
    .edit-player-form {
      padding: 16px;
      position: relative;
      .disabled-layer-root {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .5);
        z-index: 1;
      }
    }
    .player-info-title,
    .player-squad-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
    }
    .player-info {
      padding-bottom: 8px;
      .input-label {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 16px;
        margin-bottom: 4px;
        color: $gray800;
      }
      .text-field {
        width: 100%;
      }
      .team-squad {
        max-height: 250px;
        overflow: auto;
        padding-left: 1px;
        padding-top: 16px;

        .player-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .player-info {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-right: 8px;
          }
          span {
            display: inline-block;
            padding: 4px 0;
          }

          .player-actions {
            gap: 8px;
            align-items: center;
            .remove-player-btn {
              width: 18px;
              height: 18px;
              cursor: pointer;
              .icon {
                svg {
                  path {
                    fill: $error500;
                  }
                }
              }
            }
          }
        }
    }
    .no-squad-data {
      min-height: 150px;
      text-align: center;
      padding: 32px 0;
      color: $gray800;
    }
    }
    .player-squad-actions,
    .confirm-delete {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      padding: 16px;
      border-top: 1px solid $gray400;

      .left-side,
      .right-side {
        width: 50%;
      }
      .right-side {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
      }

      .delete-btn,
      .dismiss-btn {
        cursor: pointer;
      }
      .delete-btn {
        color: $error500;
      }
    }
  }
</style>
